<template>
  <app-autocomplete
    filter
    :items="items"
    :label="$t('sapNumber')"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';

export default {
  name: 'SapNumberSelect',

  computed: mapState(NAMESPACE, { items: 'creditorSapNumbers' }),

  methods: mapActions(NAMESPACE, ['fetchCreditorSapNumbers']),

  created() {
    return this.fetchCreditorSapNumbers();
  }
};
</script>

<template>
  <app-autocomplete
    filter
    :items="items"
    :label="$t('users.userName')"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';

export default {
  name: 'UserNameSelect',

  props: {
    includeCompanyUsers: {
      type: Boolean,
      default: false
    }
  },

  computed: mapState(NAMESPACE, { items: 'creditorUsernames' }),

  methods: mapActions(NAMESPACE, ['fetchCreditorUsernames']),

  created() {
    return this.fetchCreditorUsernames({ includeCompanyUsers: this.includeCompanyUsers });
  }
};
</script>
